@import "~bootstrap/scss/bootstrap";
@font-face {
  font-family: "Halimum";
  src: url("./components/assets/fonts/Halimun.ttf") format("truetype");
}
@import "variables.scss";

.color-primary {
  color: $color-title;
}

.color-secondary {
  color: $color-subtitle;
}

body {
  font-family: "Playfair Display", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
}

.norican-regular {
  font-family: "Norican", cursive;
  font-weight: 400;
  font-style: normal;
}

.playfair-display-regular {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.card-title {
  color: $color-title;
  font-family: "Norican", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 3.5rem;
}

.card-subtitle {
  color: $color-subtitle;
  font-family: "Norican", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
}

.row-bg {
  background-color: $color-bg;
}
